<template>
  <v-container>
    <div class="bg-light pa-5">
    <v-card class="mb-5" v-for="item in items" :key="item.name">
      <v-card-title>
        {{ item.name }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-select
          v-model="item.categories"
          :items="categories"
          label="Category"
          item-text="name"
          item-value="id"
          multiple
          outlined
          chips
        ></v-select>
      </v-card-text>
    </v-card>
      <v-btn color="primary" class="px-6" @click="updateHallwayCategoris">Save</v-btn>
    </div>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";

export default {
  name: "HallwayCategories",
  mounted(){
    this.getCategories();
    this.getHallwayCategories();
  },
  data() {
    return {
      loading: false,
      items: [],
      categories: [],
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getHallwayCategories() {
      let _self = this;
      this.loading = true;
      
      this.$axios.get("/admin/hallway_categories")
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    getCategories() {
      let _self = this;
      this.loading = true;
      this.$axios.get("/admin/category/list?pagination=false")
        .then((response) => {
          _self.categories = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    updateHallwayCategoris() {
      let _self = this;
      this.loading = true;

      this.$axios.put("/admin/hallway_categories", {
        hallway_categories: _self.items
      })
        .then((response) => {
          this.loading = false;

          if(response.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
          } else {
            _self.setAlert({
              show: true,
              variant: "error",
              message: response.data.message,
              dismissCountDown: 5000,
            });
          }
        })
        .catch(function () {
          this.loading = false;
        });
    }
  },
};
</script>

<style scoped>
  .bg-light {
    background-color: rgb(251, 253, 253);
  }
</style>